@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";

/* html,
body {
    height: 100%;
}

body {
    margin: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
} */

body {
    height: 100vh;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.hide {
    display: none !important;
}

.show {
    display: unset !important;
}

.body-main-container {
    min-height: 95vh;
}

.mt-90 {
    margin-top: 90px;
}

.date-text {
    text-align: left;
}

.make-gold {
    background-color: gold
}

@media (max-width:762px) {
    // .fixed-header {
    //     margin-top: 16px;
    // }
    .fixed-header mat-tab-header {
        position: fixed !important;
        bottom: 0 !important;
        z-index: 1000 !important;
        width: 100% !important;
    }
    .fixed-header mat-ink-bar {
        top: 1px !important;
        height: 3px !important;
    }
}

@media(max-width:550px) {
    .mt-90 {
        margin-top: 60px;
    }
}

.add-btn {
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: -1004;
}

td {
    text-align: left !important;
    vertical-align: middle !important;
}

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~mdb-angular-ui-kit/assets/scss/mdb.scss';
.fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
}